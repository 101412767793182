@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Fira+Sans&display=swap');

// .leaderboard-container {
//     max-width: 600px;
//     margin: 0 auto;
//   }
  
//   .leaderboard-table {
//     width: 100%;
//     border-collapse: collapse;
//     margin-top: 20px;
//   }
  
//   .leaderboard-table th,
//   .leaderboard-table td {
//     padding: 8px;
//     text-align: center;
//     border: 1px solid #ddd;
//   }
  
//   .leaderboard-table th {
//     background-color: #f2f2f2;
//   }
  
//   .leaderboard-table tbody tr:nth-child(even) {
//     background-color: #f9f9f9;
//   }

body, h1, h2, h3, h4, h5, h6, p{
    margin:0px;
    padding:0px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

#homeNav {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

#homeNavIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background: linear-gradient(45deg, #1a1a1a, #333333, #595959);
  background-size: 200% 200%;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  animation: pulse 2s infinite;

  &:hover {
    transform: scale(1.2);
    background-position: right bottom;
    transition: transform 0.5s, background-position 0.5s;
  }
}

//Landing
#landing{
    min-height: 100vh;
    min-width: 100vw;
    background-image: url('./assets/Cloudy.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
}

.image_modal {
    // display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .image_modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    width: 80%;
  }
  
  .image_close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  /* Styling for the upload button */
  #uploadBtn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  #uploadBtn:hover {
    background-color: #45a049;
  }


.button {
background-color: #89cff0;
border: none;
color: white;
padding: 10px 20px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
margin: 5px;
cursor: pointer;
}

.button:hover {
background-color: #71bde0;
}

//Landing
#holyLyrics{
    font-family: 'Fira Sans', sans-serif;
    color: white;
    padding-top: 50px;
}

.thumbnail {
  display: flex;
  align-items: center;
  height: 30vh;
  margin: 20px;
  box-shadow: 0 5px 10px #aaa;
  padding: 2px;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  transform: rotate(var(--rotation));
  background-color: white;
  user-select: none;
  // z-index: -1;

  &.selected {
    border: 5px solid #0af;
  }

  @media (min-width: 768px) {
    &:hover {
      z-index: 1;
      transform: scale(1.2);
    }
  }
}

.thumbnail img {
  height: 100%;
  border-radius: inherit;
}

#imgGridHolder{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // margin: 40px 0 10vh;
  overflow-x: hidden;
  min-height: 100vh;
  max-height: fit-content;
  overflow-y: hidden;
  font-family: 'Fira Sans', sans-serif;
}

#imgGridHolder hr {
  width: 100%;
  height: 2px;
  background-color: #888;
  border: none;
  margin: 20px 0;
}

#imageModal {
  height: 90vh;
  width: 90vw;
  // background-color: black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
}

#imageModalImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 1;
}

#imageModalBackgroundOverlay {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1; /* Lower z-index compared to the modal */
}

.closeModal {
  position: absolute;
  top: 1px;
  right: 1px;
  font-size: 40px;
  color: white;
  cursor: pointer;
}

.album {
  min-width: 200px;
  max-width: fit-content;
  height: fit-content;
  border-radius: 8px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.album:hover {
  transform: scale(1.1);
}

.album-gradient {
  background: linear-gradient(
    45deg,
    hsl(var(--randomHue), 40%, 80%),
    hsl(calc(var(--randomHue) + 60), 40%, 80%)
  );
}

#albumContainer{
  width: 100vw;
  text-align: center;
}

//Remember
#rememberFeed {
  background-image: url('./assets/rememberBg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0 10px;
  overflow-x: hidden;
  min-height: 100vh;
  max-height: fit-content;
  row-gap: 10px;
  margin-top: -5px; /* Add a negative margin to offset the cut-off */
  padding: 20px; /* Add padding to create space between notes */
}

.remember {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0;
  padding: 10px;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  box-shadow: 0 5px 10px #aaa;
  transform: rotate(calc(var(--rotation) * 5deg));
  background-color: hsl(calc(var(--hue) + var(--random-offset)), 70%, 90%);
}

.remember h4 {
  margin: 0;
  font-weight: bold;
  z-index: 1; /* Ensure the text is on top of the tape */
}

.remember p {
  font-family: 'Caveat', cursive;
  margin: 10px 0 0;
  font-size: 20px;
}

.remember::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translateX(-50%) rotate(45deg);
  background-color: #f4f4f4;
  border-top: 2px solid #888;
  border-left: 2px solid #888;
  border-radius: 0 0 2px 2px;
  z-index: 0; /* Adjust the z-index to place the pushpin below the text */
}

.remember.selected {
  border: 5px solid #0af;
}

/* Random offset for background color */
.remember:nth-child(odd) {
  --random-offset: calc(var(--rotation) * 10);
}

.remember:nth-child(even) {
  --random-offset: calc(var(--rotation) * -10);
}

/*Admin*/
#adminPage{
  min-height: 100vh;
  min-width: 100vw;
  height: fit-content;
  width: fit-content;
  background-color: black;
  color:#60f565
}

/*Quotes*/
.quotes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.quotes-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.quote-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  margin: 10px;
  position: relative;
  overflow: hidden;
}

.quote-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.quote-author {
  font-size: 14px;
  text-align: right;
  color: #888;
}

.quote-card:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  height: 1px;
  background-color: #ccc;
}

.quote-card:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 1px;
  background-color: #ccc;
}